import * as React from 'react';

export function SearchIcon({ style }: any): JSX.Element {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path d="M9.90664671,8.79167096 L11.2684021,9.7684021 L14.5,13 L13,14.5 L9.75326538,11.2532654 L8.79102418,9.90713055 C7.87334026,10.5935176 6.73413965,11 5.5,11 C2.46243388,11 0,8.53756612 0,5.5 C0,2.46243388 2.46243388,0 5.5,0 C8.53756612,0 11,2.46243388 11,5.5 C11,6.73442961 10.5933266,7.87387556 9.90664671,8.79167096 Z M5.5,9.2 C7.54345357,9.2 9.2,7.54345357 9.2,5.5 C9.2,3.45654643 7.54345357,1.8 5.5,1.8 C3.45654643,1.8 1.8,3.45654643 1.8,5.5 C1.8,7.54345357 3.45654643,9.2 5.5,9.2 Z" />
    </svg>
  );
}
