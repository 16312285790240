import * as React from "react";

export function DayModeIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path d="M8,13 C5.23857625,13 3,10.7614237 3,8 C3,5.23857625 5.23857625,3 8,3 C10.7614237,3 13,5.23857625 13,8 C13,10.7614237 10.7614237,13 8,13 Z M8,2 C7.44771525,2 7,1.55228475 7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 C9,1.55228475 8.55228475,2 8,2 Z M12.2426407,3.75735931 C11.8521164,3.36683502 11.8521164,2.73367004 12.2426407,2.34314575 C12.633165,1.95262146 13.26633,1.95262146 13.6568542,2.34314575 C14.0473785,2.73367004 14.0473785,3.36683502 13.6568542,3.75735931 C13.26633,4.1478836 12.633165,4.1478836 12.2426407,3.75735931 Z M14,8 C14,7.44771525 14.4477153,7 15,7 C15.5522847,7 16,7.44771525 16,8 C16,8.55228475 15.5522847,9 15,9 C14.4477153,9 14,8.55228475 14,8 Z M12.2426407,12.2426407 C12.633165,11.8521164 13.26633,11.8521164 13.6568542,12.2426407 C14.0473785,12.633165 14.0473785,13.26633 13.6568542,13.6568542 C13.26633,14.0473785 12.633165,14.0473785 12.2426407,13.6568542 C11.8521164,13.26633 11.8521164,12.633165 12.2426407,12.2426407 Z M8,14 C8.55228475,14 9,14.4477153 9,15 C9,15.5522847 8.55228475,16 8,16 C7.44771525,16 7,15.5522847 7,15 C7,14.4477153 7.44771525,14 8,14 Z M3.75735931,12.2426407 C4.1478836,12.633165 4.1478836,13.26633 3.75735931,13.6568542 C3.36683502,14.0473785 2.73367004,14.0473785 2.34314575,13.6568542 C1.95262146,13.26633 1.95262146,12.633165 2.34314575,12.2426407 C2.73367004,11.8521164 3.36683502,11.8521164 3.75735931,12.2426407 Z M2,8 C2,8.55228475 1.55228475,9 1,9 C0.44771525,9 1.39800648e-16,8.55228475 3.83475851e-17,8 C-6.31054776e-17,7.44771525 0.44771525,7 1,7 C1.55228475,7 2,7.44771525 2,8 Z M3.75735931,3.75735931 C3.36683502,4.1478836 2.73367004,4.1478836 2.34314575,3.75735931 C1.95262146,3.36683502 1.95262146,2.73367004 2.34314575,2.34314575 C2.73367004,1.95262146 3.36683502,1.95262146 3.75735931,2.34314575 C4.1478836,2.73367004 4.1478836,3.36683502 3.75735931,3.75735931 Z" />
    </svg>
  );
}
