import * as React from "react";

export const SearchMinus = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4328923,11.4143869 C12.6825531,11.419022 12.9308089,11.5165953 13.1213203,11.7071068 L15.9497475,14.5355339 C16.3402718,14.9260582 16.3402718,15.5592232 15.9497475,15.9497475 C15.5592232,16.3402718 14.9260582,16.3402718 14.5355339,15.9497475 L11.7071068,13.1213203 C11.5165953,12.9308089 11.419022,12.6825531 11.4143869,12.4328923 C10.2100155,13.412674 8.6735964,14 7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 C10.8659932,0 14,3.13400675 14,7 C14,8.6735964 13.412674,10.2100155 12.4328923,11.4143869 Z M7,12 C9.76142375,12 12,9.76142375 12,7 C12,4.23857625 9.76142375,2 7,2 C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 Z M4,6 L10,6 L10,8 L4,8 L4,6 Z"
      id="magnifierSmaller"
    />
  </svg>
);
