export const de = {
  BALANCE_DISPENSE: 'abziehen',
  BALANCE_PLACEHOLDER: 'Anderer Wert',
  BALANCE_DEPOSIT: 'aufladen',
  BALANCE_TITLE: 'Balance',
  ADD_TAG_PLACEHOLDER: 'Neuer tag',
  ARTICLE_FORM_ADD_TAG: 'tag hinzufügen',
  ARTICLE_ADD_FORM_TAG: 'Tags',
  ARTICLE_HEADLINE: 'Bedürfnis',
  ARTICLE_LINK: 'Bedürfnisse',
  ARTICLE_ADD_LINK: 'Neues Bedürfnis anlegen',
  ARTICLE_ADD_FROM_HEADLINE: 'Neues Bedürfnis anlegen',
  ARTICLE_ADD_FROM_ACCEPT: 'speichern',
  ADD_ITEM: 'Änderungen speichern',
  DELETE_ITEM: 'löschen',
  DELETE_TAG: 'tag löschen',
  DELETE_ARTICLE_LABEL: 'Bedürfnis löschen',
  BUY_ARTICLE_PLACEHOLDER: 'Bedürfnis suchen',
  ARTICLE_ADD_FORM_DETAILS: 'Details',
  ARTICLE_ADD_FORM_HISTORY: 'Verlauf',
  ARTICLE_ADD_FORM_BARCODE: 'Barcode',
  ARTICLE_FORM_ADD_BARCODE: 'Barcode hinzufügen',
  ARTICLE_ADD_FORM_METRICS: 'Statistiken',
  ARTICLE_ADD_FORM_NAME_LABEL: 'Name:',
  ARTICLE_ADD_FORM_BARCODE_LABEL: 'Barcode:',
  ARTICLE_ADD_FORM_AMOUNT_LABEL: 'Spendenvorschlag:',
  ARTICLE_LOADING_BY_BARCODE: 'fetching article by barcode',
  ARTICLE_FETCHED_BY_BARCODE: 'you just scanned:',
  ARTICLES_COULD_NOT_BE_LOADED:
    'Hier is nix. Internet ok?',
  ARTICLE_COULD_NOT_BE_CREATED:
    'Could not create the article maybe check your internet connection',
  ARTICLE_COULD_NOT_BE_LOADED_BY_BARCODE:
    'Could not load the article by barcode',
  TALLY_HEADER: 'Postlab Bar',
  USERS_LOADING_FAILED:
    'Oops could not load Nutzer*in :-( Maybe check your internet connection and try it again',
  USERS_CREATION_FAILED: 'Ups, irgendwas geht nicht',
  USERS_CREATION_FAILED_USER_EXIST: 'Den User gibts schon',
  USER_EDIT_USER_FAILED:
    'Ging nicht, Internet ok?',
  USER_SEARCH_LINK: 'User finden',
  USER_ARTICLE_LINK: 'Bedürfnis befriedigen',
  USER_SEARCH_HEADLINE: 'User finden',
  USER_ACTIVE: 'Aktive Nutzer*in',
  USER_INACTIVE: 'Inaktive Nutzer*in',
  USER_ACTIVE_LINK: 'Aktive Nutzer*in',
  USER_INACTIVE_LINK: 'Inaktive Nutzer*in',
  USER_TRANSACTIONS: 'Umsätze',
  USER_TRANSACTIONS_LINK: 'Alle Umsätze',
  USER_TRANSACTIONS_LOADING_ERROR: 'Could not load transactions',
  USER_TRANSACTION_CREATION_ERROR: 'Could not create transaction',
  USER_TRANSACTION_DELETION_ERROR: 'Could not undo transaction',
  USER_TRANSACTIONS_SENDER: 'Sender',
  USER_TRANSACTIONS_TABLE_ARTICLE: 'Bedürfnis',
  USER_TRANSACTIONS_TABLE_SENDER: 'Sender',
  USER_TRANSACTIONS_TABLE_RECIPIENT: 'Empfänger',
  USER_TRANSACTIONS_TABLE_COMMENT: 'Kommentar',
  USER_TRANSACTIONS_TABLE_AMOUNT: 'Betrag',
  USER_TRANSACTIONS_TABLE_CREATED: 'erstellt',
  USER_TRANSACTIONS_TABLE_LOAD_NEXT_ROWS: 'mehr',
  USER_TRANSACTIONS_RECIPIENT: 'Empfänger',
  USER_TRANSACTION_CREATE: 'Sende Geld an eine*n Freund*in',
  USER_TRANSACTION_CREATE_SUBMIT_TITLE:
    'Abschicken um diesen Betrag an eine*n Freund*in zu schicken',
  USER_TRANSACTION_CREATE_LINK: 'Geld senden',
  USER_TRANSACTION_CREATE_CUSTOM_HEADLINE: 'Custom transaction',
  USER_TRANSACTION_CREATE_CUSTOM_DEPOSIT_BUTTON: 'Charge wallet',
  USER_TRANSACTION_CREATE_CUSTOM_DISPENSE_BUTTON: 'Pay purchase',
  USER_TRANSACTION_FROM_AMOUNT_LABEL: 'Menge',
  USER_TRANSACTION_UNDO: 'undo',
  USER_SELECTION_LIST_LABEL: 'Nutzer*in',
  USER_CREATE_HEADLINE: 'Neue*n Nutzer*in anlegen',
  USER_CREATE_TRIGGER: 'Neue*n Nutzer*in anlegen',
  USER_CREATE_NAME_LABEL: 'Neue*n Nutzer*in anlegen',
  USER_EDIT_LINK: 'Nutzer*in bearbeiten',
  USER_EDIT_HEADLINE: 'Nutzer*in bearbeiten',
  USER_EDIT_TRIGGER: 'speichern',
  USER_EDIT_NAME_LABEL: 'Name',
  USER_EDIT_MAIL_LABEL: 'Email',
  USER_EDIT_ACTIVE_LABEL: 'Nutzer*in ist inaktiv',
  USER_EDIT_ACTIVE_WARNING: 'you can not enable a disabled user. So take care',
  PAYMENT_DEPOSIT_LABEL: 'Deinen Geldbeutel belasten',
  PAYMENT_DISPENSE_LABEL: 'Was du zahlen musst',
  BACK_BUTTON: 'zurück',
  SETTINGS_LOADED_FAILED:
    'Could not load settings, falling back to default settings',
  PAGER_PREV: 'vorherige',
  PAGER_NEXT: 'nächste',
  SEARCH: 'Suche',
  CREATE_USER_TO_USER_TRANSACTION_SUCCESS: 'Du hast geschickt an Nutzer*in',
  CREATE_USER_TO_USER_TRANSACTION_USER: 'Username',
  CREATE_USER_TO_USER_TRANSACTION_COMMENT: 'Notiz hinzufügen',
  TRANSACTION_EMPTY_STATE: 'Noch keine Umsätze',
  METRICS_HEADLINE: 'Statistiken',
  ARTICLE_USAGE_COUNT_LABEL: 'Das Bedürfnis wurde {value} mal gestillt',
  ARTICLE_ACTIVE_HEADLINE: 'Aktive Bedürfnisse',
  ARTICLE_INACTIVE_HEADLINE: 'Inaktive Bedürfnisse',
  SPLIT_INVOICE_LINK: 'Rechnung aufteilen',
  SPLIT_INVOICE_HEADLINE: 'Rechnung aufteilen',
  SPLIT_INVOICE_SUBMIT: 'abschicken',
  SELECT_RECIPIENT: 'Empfänger*in wählen',
};
